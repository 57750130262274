/* You can add global styles to this file, and also import other style files */

@import 'node_modules/@danielmoncada/angular-datetime-picker/assets/style/picker.min.css';

html {
  overflow: hidden;
}

:root {
  .lpx-theme-dark {
    --lpx-logo: url('/assets/images/logo/logo-light.png');
    --lpx-logo-icon: url('/assets/images/logo/logo-light-icon.png');
    --lpx-brand: #edae53;
  }

  .lpx-theme-dim {
    --lpx-logo: url('/assets/images/logo/logo-light.png');
    --lpx-logo-icon: url('/assets/images/logo/logo-light-icon.png');
    --lpx-brand: #f15835;
  }

  .lpx-theme-light {
    --lpx-logo: url('/assets/images/logo/logo-dark.png');
    --lpx-logo-icon: url('/assets/images/logo/logo-dark-icon.png');
    --lpx-brand: #69aada;
  }
}

.cdk-overlay-container {
  z-index: 10000;
}

/*
 * Fix abp-typeahead dropdown hidden on modals */
ngb-typeahead-window.dropdown-menu.show {
    z-index: 10500;
}

abp-button:has(> button:disabled) {
  pointer-events: none;
}

.modal-footer {
  display: flex;
  gap: 20px;
  position: relative;
  z-index: 1000;
}

.lpx-content-wrapper {
  padding-bottom: 0.5rem;
}

abp-advanced-entity-filters > div > div > div.mb-3.mt-3 {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}
